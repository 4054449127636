import React from 'react'

const Footer = () => {
    return (
        <div className='bg-[#212121] h-64 border-t-2 border-border-primary px-48 py-7'>
            <h1>
                <span className='uppercase font-extrabold italic text-text-primary text-3xl'>Real</span>
                <span className='uppercase text-text-primary text-3xl'>Sushi</span>
            </h1>
        </div>
    )
}

export default Footer