import React from 'react'

const ReviewCard = ({ title, desc, img, user, date }) => {
    return (
        <div className='bg-btn-hover p-4 rounded-lg w-full mb-4 mt-4 text-left'>
            <div className='inline-flex mb-4'>
                <img src={img} alt='user' className='rounded-full h-12 w-12' />
                <h4 className='uppercase text-2xl mb-2 ml-4 my-auto'>{title}</h4>
            </div>
            <p className='text-sm'>{desc}</p>
            <div className='inline-flex mt-4 w-full justify-between'>
                <p>{user}</p>
                <p>{date}</p>
            </div>
        </div>
    )
}

export default ReviewCard