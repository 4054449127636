import React from 'react';
import sushiChopsticks from '../assets/sushiChopsticks.jpg';

const Hero = () => {
    return (
        <div className='w-full inline-flex flex-col md:flex-row h-auto'>
            <div className='md:w-1/2 w-full my-auto'>
                <h1 className='uppercase font-semibold text-5xl'>The most <span className='font-bold italic text-text-primary text-5xl'>real</span><br /> sushi in the world</h1>
                <p className='p-2 text-lg mt-2'>
                    Our sushi recipes are completely homemade, made with the best quality ingredients you can get.
                </p>
                <div className='inline-flex space-x-8 mt-4'>
                    <button className='bg-btn-primary py-3 px-12 rounded-lg font-semibold hover:bg-btn-hover transition'>Order now</button>
                    <p className='my-auto view-menu-btn'>View our menu</p>
                </div>
            </div>
            <div className='md:w-1/2 w-full relative mt-5'>
                <img src={sushiChopsticks} alt='Sushi between chopsticks' className='w-4/5 h-96 sm:mx-auto rounded-lg relative' />
                <p className='relative bottom-0 w-4/5 sm:text-center text-left sm:mx-auto z-10 text-sm'>
                    The sushi we make here is made from 100% recycled plastics & metals. It is our top priority to not let any ingredients go to waste.
                </p>
            </div>
        </div>
    )
}

export default Hero