import React from 'react';
import ReviewCard from './ReviewCard';
import jfkCritic from '../assets/jfkCritic.jpg';
import wrestlerCritic from '../assets/wrestlerCritic.jpg';
import sushiCritic from '../assets/sushiCritic.jpg';

const Reviews = () => {
    return (
        <div className='text-center'>
            <h2 className='uppercase text-3xl'>Our sushi is the <span className='font-bold italic text-text-primary'>best</span></h2>
            <h3 className='uppercase text-xl'>Don't believe us? Believe them.</h3>
            <div className='inline-flex flex-col'>
                <ReviewCard 
                    title='Absolutely fantastic'
                    desc='As the president of the United States of America, I absolutely recommend this Sushi shop. It absolutely blew me away. Without a doubt the best sushi I have ever tasted.'
                    img={jfkCritic}
                    user='John F. Kennedy'
                    date='21/11 - 1963'
                />
                <ReviewCard 
                    title='Muscles are growing'
                    desc='My muscles have only gotten bigger. They are rapidly growing as I am writing this. My bigg moskles. Ontop of that it tastes fantastic too. Buy now.'
                    img={wrestlerCritic}
                    user='Borat Poota'
                    date='12/03 - 2001'
                />
                <ReviewCard 
                    title='Sushi good'
                    desc="As a piece of sushi, I highly recommend this resturant. They know their stuff. All of their sushi is on top tier. Since I have no mouth I can't actually taste it. But from years of experience, I can tell it's good."
                    img={sushiCritic}
                    user='Sushi F. Critic'
                    date='11/09 - 1768'
                />
            </div>
        </div>
    )
}

export default Reviews