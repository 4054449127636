import React, { useState } from 'react';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <div className='py-3 px-36 bg-primary border-b-2 border-border-primary'>
            <nav className='inline-flex w-full justify-between'>
                <div>
                    <h1>
                        <span className='uppercase font-extrabold italic text-text-primary text-3xl'>Real</span>
                        <span className='uppercase text-text-primary text-3xl'>Sushi</span>
                    </h1>
                </div>
                <div className='desktop-menu space-x-8 text-white uppercase my-auto hidden lg:inline-flex'>
                    <p className='menu-active relative cursor-pointer'>Home</p>
                    <p className='cursor-pointer menu-item'>Menu</p>
                    <p className='cursor-pointer menu-item'>Order</p>
                    <p className='cursor-pointer menu-item'>Contact Us</p>
                </div>
                <button className='mobile-menu-button text-white uppercase my-auto block lg:hidden' onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>Menu</button>
                {isMobileMenuOpen && (
                    <div className='mobile-menu fixed top-0 left-0 w-full h-full bg-black text-white flex flex-col justify-center items-center z-20'>
                        <button className='absolute text-4xl top-4 right-4 text-white' onClick={() => setIsMobileMenuOpen(false)}>X</button>
                        <p className='menu-active relative cursor-pointer mb-4'>Home</p>
                        <p className='cursor-pointer menu-item mb-4'>Menu</p>
                        <p className='cursor-pointer menu-item mb-4'>Order</p>
                        <p className='cursor-pointer menu-item'>Contact Us</p>
                    </div>
                )}
            </nav>
        </div>
    )
}

export default Navbar