import React from 'react';
import sushiChef from '../assets/sushiChef.jpg';

const Intro = () => {
    return (
        <div className='w-full inline-flex flex-col md:flex-row h-auto mt-20'>
            <div className='md:w-1/2 w-full my-auto'>
                <img src={sushiChef} alt='Sushi between chopsticks' className='h-auto w-11/12 rounded-lg relative' />
            </div>
            <div className='md:w-1/2 w-full relative my-auto'>
                <p className='p-2 text-lg mt-2'>
                    Here at <span className='uppercase font-semibold'>Real Sushi</span>, we take great pride in making all of our dishes completely by hand. Producing both the best tasting, and the best feeling sushi in the world.
                </p>
            </div>
        </div>
    )
}

export default Intro