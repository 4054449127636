import React from 'react'
import Hours from './Hours'
import Reviews from './Reviews'

const SplitGrid = () => {
    return (
        <div className='w-full inline-flex flex-col lg:flex-row h-auto mt-20'>
            <div className='lg:w-1/2 w-full mt-16 mb-4'>
                <Hours />
            </div>
            <div className='lg:w-1/2 w-full'>
                <Reviews />
            </div>
        </div>
    )
}

export default SplitGrid