import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Intro from '../components/Intro'
import SplitGrid from '../components/SplitGrid'
import Footer from '../components/Footer'

const HomePage = () => {
    return (
        <div className='homepage'>
            <Navbar />
            <div className='mx-auto lg:px-36 lg:py-14 md:px-20 md:py-10 px-10 py-10 text-white'>
                <Hero />
                <Intro />
                <SplitGrid />
            </div>
            <Footer />
        </div>
    )
}

export default HomePage