import React from 'react'

const Hours = () => {
    return (
        <div className='w-1/2 mx-auto bg-btn-hover text-center py-6 pb-12 px-4 rounded-lg'>
            <h1 className='text-2xl'>Opening Hours</h1>
            <hr className='mt-2 mb-2' />
            <table className='text-left w-full'>
                <tr>
                    <td>Monday:</td>
                    <td className='text-right'>09:30 - 21:00</td>
                </tr>
                <tr>
                    <td>Tuesday:</td>
                    <td className='text-right'>09:30 - 21:00</td>
                </tr>
                <tr>
                    <td>Wednesday:</td>
                    <td className='text-right'>09:30 - 21:00</td>
                </tr>
                <tr>
                    <td>Thursday:</td>
                    <td className='text-right'>09:30 - 21:00</td>
                </tr>
                <tr>
                    <td>Friday:</td>
                    <td className='text-right'>09:30 - 22:00</td>
                </tr>
                <tr>
                    <td>Saturday:</td>
                    <td className='text-right'>11:30 - 22:00</td>
                </tr>
                <tr>
                    <td>Sunday:</td>
                    <td className='text-right'>12:30 - 21:00</td>
                </tr>
            </table>
        </div>
    )
}

export default Hours